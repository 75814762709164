import React from "react";
import "./App.css";
import "./Styles/Type.css";
import Menu from "./Components/Menu";
import ContTeam from "./Components/ContTeam";
import Landing from "./Components/Landing";
import Title from "./Components/Title";
import Video from "./Components/Video";
import About from "./Components/About";
import ButtonTop from "./Components/ButtonTop";
import Contact from "./Components/Contact";
import Development from "./Components/Development";
import peachLogo from "./Assets/charactercarousel.png";

function App() {
  return (
    <div className="App" id="start">
      <Menu />
      <ButtonTop />
      <div className="block">
        <Landing />
      </div>
      <div className="block" id="trailer">
        <Video />
      </div>
      <div className="block" id="about">
        <Title
          text="What's Shelf Life about?"
          style={{ textAlign: "center" }}
        />

        <About />
      </div>
     
      <div className="block" id="team">
           <ContTeam/>
      </div>
      <div className="block" id="development">
        <Title text="Development" />
        <Development />
      </div>
      <div className="block" id="contact">
        <Contact />
      </div>

      <footer className="footer">
        <img src={peachLogo} alt="Peach Logo" className="footer-logo" />
      </footer>
    </div>
  );
}

export default App;
