import React from 'react';
import PropTypes from 'prop-types';
import '../Styles/ListItems.css'; // Import CSS for styling the boxes

const ListItems = ({ items }) => {
  return (
    <div className="list-items-container">
      {items.map((item, index) => (
        <div key={index} className="list-item-box">
          <a href={item.link} target="_blank" rel="noopener noreferrer">
          <p>{item.text}</p>
          </a>
        </div>
      ))}
    </div>
  );
};

ListItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ListItems;

