import React from 'react';
import PropTypes from 'prop-types';

function Button({
  link = '#', 
  text = 'Default Text', 
  type = 'default', 
  show = 'show'
}) {
  const classNames = `${type} ${show} disc-desk`;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className={classNames}>
      <button>
        {text}
      </button>
    </a>
  );
}

// Defining the prop types
Button.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string, 
  type: PropTypes.string, 
  show: PropTypes.oneOf(['show', 'hide']),
};

export default Button;
