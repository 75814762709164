import React from 'react';
import '../Styles/Video.css';
import sampleVideo from '../Assets/video.mov'; // Import your local video file

function Video() {
  return (
    <div className="video-container">
      <video  controls autoPlay >
        <source src={sampleVideo} type="video/mp4" /> {/* Adjust the type if your video is not mp4 */}
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default Video;

