import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ text, style }) => (
  <h2 style={style}>
    {text}
  </h2>
);

Title.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object
};

Title.defaultProps = {
  style: {}
};

export default Title;
