import React, { useState } from 'react';
import '../Styles/Menu.css';
import { FaBars } from 'react-icons/fa';  // Import the hamburger icon from react-icons
import libraryIcon from '../Assets/UI/mark-white.png';

function Menu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="App-header">

<nav className="menu">

<a href="#trailer">Trailer</a>  
<a href="#about">About</a>  
<a href="#team">Team</a>  
<a href="#development">Process</a> 
{/* <a href="#donate">Donate</a>   */}
<a href="#contact">Contact</a>  
<a target="_blank" rel="noopener noreferrer"className="standout" href="https://shelflifegame.scss.tcd.ie/index.html">PLAY</a>  

</nav>
      {/* Hamburger icon for mobile */}
      <div className="mobile-menu-icon" onClick={toggleMenu}>
        <FaBars />
      </div>

      {/* Fullscreen popup menu */}
      {isMenuOpen && (
        <div className="popup-menu">
      
          <nav className="popup-nav">
            <a href="#trailer" onClick={toggleMenu}>Trailer</a>
            <a href="#about" onClick={toggleMenu}>About</a>
            <a href="#development" onClick={toggleMenu}>Process</a>
            <a href="#team" onClick={toggleMenu}>Team</a>
            {/* <a href="#donate" onClick={toggleMenu}>Donate</a> */}
            <a href="#contact" onClick={toggleMenu}>Contact</a>
            
          </nav>
        </div>
      )}

<div className='mark'>     
    <a target="_blank" rel="noopener noreferrer" href="https://librarytechnology.org/libraries/">
        Libraries Near You
    </a>  
    <img src={libraryIcon} alt="Library Icon" className="mark-image" />
</div>

    </header>
  );
}

export default Menu;


